<template>
    <div>
        <Header headerType="advance_result"/> 


        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '') ">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <h1>{{ count }}</h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                        <div class="filters-plus" @click="active_filter=true">
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-filtre-off${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                            <p>{{$t('Filters')}}</p>
                        </div>
                    </div>
                    <div :class="'box-filter ' + (active_filter ? 'active' : '')">
                        <div class="filter-inner apply" >
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-ok${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                            <p>{{$t('Apply')}}</p>
                        </div>
                        <div class="filter-inner reset" @click="handleReset">
                            <img src="@/assets/dist/2019/images/icon/checklist-reload.svg" alt="" >
                            <p>{{$t('Reset')}}</p>
                        </div>
                        <div class="filter-inner cancel" @click="active_filter=false">
                            <img :src="require(`@/assets/dist/2019/images/icon/ic-close${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                            <p>{{$t('Cancel')}}</p>
                        </div>
                    </div>
                    
                    <div :class="'menu-filter ' + (active_filter ? 'active' : '')" style="width:fit-content;">
                        <ul>
                            <li class="title-filter" style="pointer-events: none;"> <h5> Filters </h5> </li>
                            <li>
                                 <label class="table-check group-checkbox" style="cursor: pointer;">
                                    <input type="checkbox" value="1" v-model="temp_filter">
                                    <span class="checkbox-table"></span>
                                    <div> {{$t('Hide addition range')}} </div>
                                </label>
                            </li>
                        </ul>
                       
                    </div>
                    <div class="advance box-fix-left" ref="menuFlag">
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0; margin-top: 10px;"></span>
                                        Prise En Charge
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body" @click="active_filter=false">
                    <div class="body-fix">
                        <div class="body-custom"> 
                            <div class="box-btn-menu">  
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">       
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">
                            <!-- <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                                <div class="clearfix"></div>
                            </div> -->
                            <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column" /> 
                            <ModalSaveKeyword :type="type" />
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes">
                                    <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column" />
                                    <ColumnThead :page="page" :column="orderColumn" :default_column="default_column" />  
                                    <tbody> 
                                        <template v-for="(item, key) in data.data" >
                                            <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)">                 
                                            <template v-if="filter_indication_jo_hide_addition_range(item.indication_jo_info[sub_key])">
                                                <td>
                                                    <label class="table-check" v-if="sub_key===0">
                                                        <input type="checkbox"  v-model="select_export" :value="item.id">
                                                        <span class="checkbox-table"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <!-- <div class="icon-fiche" v-if="sub_key===0"></div> -->
                                                    <div v-if="sub_key===0 && isAdmin" >
                                                        <a :href="modifyPage('indication_jo', item.id)" target="_blank" rel="noopener"> 
                                                            <em class="icon-edit"></em>
                                                        </a>
                                                    </div>
                                                </td>

                                                
                                                <template v-for="(column) in orderColumn" :key="column.code" >

                                                    <td v-if="column.Status && column.code == 'prise_en_charge_001'" :code="column.code">
                                                        <template v-if="sub_key===0">{{check_empty(item.transparence_categorie.name)}}</template>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_002'" :code="column.code">                                                           
                                                        <template v-if="sub_key===0">{{check_empty(item.transparence_categorie.dci_new)}}</template>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_003'" :code="column.code">
                                                        <Readmore v-if="sub_key===0" :longText="item.indication" />
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_004'" :code="column.code">
                                                        <span v-html="indexation(item.indication_jo_info[sub_key])"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_005'" :code="column.code">
                                                        <span v-html="prise_en_charge_array(item.indication_jo_info[sub_key])"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_006'" :code="column.code">
                                                        <span v-html="date_application(item.indication_jo_info[sub_key], 'SS')"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_007'" :code="column.code">
                                                        <span v-html="date_application(item.indication_jo_info[sub_key], 'COLL')"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_008'" :code="column.code">
                                                        <span v-html="date_application(item.indication_jo_info[sub_key], 'T2A')"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_009'" :code="column.code">
                                                        <span v-html="date_application(item.indication_jo_info[sub_key], 'Retro')"></span>
                                                    </td>
                                                    <td v-else-if="column.Status && column.code == 'prise_en_charge_010'" :code="column.code">
                                                        <span v-html="date_application(item.indication_jo_info[sub_key], 'Prise en charge precoce')"></span>
                                                    </td>

                                                </template> 
                                            </template>
                                            </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                             
                            
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    </div>   

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="PriseEnCharge" />

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="PriseEnCharge" />

                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
    
</template>

<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import Readmore from '@/components/elements/readmore.vue'; 
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import { Base64 } from 'js-base64';
import ModalConfigColumn from '@/components/elements/modal_config_column.vue';
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import TablePrix from '@/components/advanced_result/table_prix.vue';
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import { currentDate, isAdmin, isUserFrench, eventHandlerResize, DDMMMYY, modifyPage, check_empty, odd_even_border } from '@/utils';
import Loader from "@/components/Layout/Loader";

export default {
    name: 'advanced_result_prise_en_charge',
    components: {        
        Header,
        Footer,  
        Readmore, 
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalConfigColumn,
        ModalSaveKeyword,
        TablePrix,
        TableEssaisClinique,
        Loader
    },
    data() {
        return {
            page : "prise_en_charge",
            active_filter : false,
            filter : false,
            temp_filter : false,
        }
    },
    mounted() {
        //search
        this.request_data({ mounted : true, order_by : 'indication_jos.id', sort : 'desc' }) 
        document.body.classList.add('body-bg-gray') 
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }  
    },
    methods: {
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'indication_jos.id'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_prise_en_charge_excel`, [paramObj, currentDate()+"_advanced_prise_en_charge" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_prise_en_charge`, paramObj ) 
            } 
            
            if(!param.mounted){
                this.selectTab('expert')
            }
        },  
        selectExport(){
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        },
        handleReset(){
            this.temp_filter = false
            this.handleSubmit()
        },
        handleSubmit(){
            this.filter = this.temp_filter
            this.active_filter = false
            this.route_filter()
        },
        route_filter(){
            let route_param = { 'base64Search' : Base64.encode(Base64.decode(this.$route.params['base64Search']) + 'Prise_en_Charge@filter_indication_jo_hide_addition_range=true|')}
            if(this.filter){
                 this.$store.dispatch(`advanced_result/result_prise_en_charge`, route_param)
            }else{
                this.$store.dispatch(`advanced_result/result_prise_en_charge`, this.$route.params)
            }
        },
        filter_indication_jo_hide_addition_range(param){
            if(param && param.indication_jo_info_smr && param.indication_jo_info_smr[0] && param.indication_jo_info_smr[0].transparence_smr && param.indication_jo_info_smr[0].transparence_smr[0] && param.indication_jo_info_smr[0].transparence_smr[0].transparence){ 

                let item = param.indication_jo_info_smr[0].transparence_smr[0].transparence
                if(item['trs_particularite'] == "Complement de gamme" && this.filter)
                    return false
            }             
                return true
            },
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
        date_application(param, title){
            let date_jo = ''
            let particularites = ''
            let link_info = ''

            if(title === 'SS'){
                date_jo = param['ss_date_info'] ? param['ss_date_info'] : ""
                particularites = param['particularites_ss'] ? param['particularites_ss'] : ""
                if(particularites=='Radiation'){
                    date_jo = param['ss_date_application']
                }
                link_info = param['ss_link_info'] ? param['ss_link_info'] : ""
            }
            else if(title === 'COLL'){
                date_jo = param['coll_date_info'] ? param['coll_date_info'] : ""
                particularites = param['particularites_coll']? param['particularites_coll'] : ""
                if(particularites=='Radiation'){
                    date_jo = param['coll_date_application']
                }
                link_info = param['coll_link_info'] ? param['coll_link_info'] : ""
            }
            else if(title === 'T2A'){
                date_jo = param['t2a_date_info'] ? param['t2a_date_info'] : ""
                particularites = param['particularites_t2a']? param['particularites_t2a'] : ""
                if(particularites=='Radiation'){
                    date_jo = param['t2a_date_application']
                }
                link_info = param['t2a_link_info'] ? param['t2a_link_info'] : ""
            }
            else if(title === 'Retro'){
                date_jo = param['retro_date_info'] ? param['retro_date_info'] : ""
                particularites = param['particularites_retro']? param['particularites_retro'] : ""
                if(particularites=='Radiation'){
                    date_jo = param['retro_date_application']
                }
                link_info = param['retro_link_info'] ? param['retro_link_info'] : ""
            }
            else if(title === 'Prise en charge precoce'){
                date_jo = param['prise_en_charge_precoce_date_info'] ? param['prise_en_charge_precoce_date_info'] : ""
                particularites = param['particularites_prise_en_charge_precoce']? param['particularites_prise_en_charge_precoce'] : ""
                if(particularites=='Radiation'){
                    date_jo = param['prise_en_charge_precoce_date_application']
                }
                link_info = param['prise_en_charge_precoce_link_info'] ? param['prise_en_charge_precoce_link_info'] : ""
            }
            let temp_date = ""
            if(date_jo || link_info || particularites){
                temp_date += link_info ? "<a href=\"" + link_info + "\" target=\"_blank\">" + this.DDMMMYY(date_jo) + "</a>" : this.DDMMMYY(date_jo)
                temp_date += particularites ? "<p style=\"margin-bottom: 0;\">"+ this.$t(particularites)+"</p><br/>" : ""
            }
            // if (date_jo && param.indication_jo_info_smr[0]) {
            //     let delay = this.datediff(param.indication_jo_info_smr[0].transparence_smr[0].transparence[0].trs_date, date_jo)
            //     if(this.datediff(param.indication_jo_info_smr[0].transparence_smr[0].transparence[0].trs_date, date_jo))
            //         temp_date += "<p style=\"margin-bottom: 0;\">" + delay + " " + this.$t('days') + "</p>";
            // }
            temp_date = this.check_empty(temp_date)
            return temp_date
        },
        indexation(param){
            let result = ''
            if(param && param.indication_jo_info_smr && param.indication_jo_info_smr[0] && param.indication_jo_info_smr[0].transparence_smr && param.indication_jo_info_smr[0].transparence_smr[0] && param.indication_jo_info_smr[0].transparence_smr[0].multilanguage_indexations){ 
                param = param.indication_jo_info_smr[0].transparence_smr[0].multilanguage_indexations
                for(let sub_key = 0; sub_key < param.length; sub_key++){
                    result += param[sub_key].index_fr
                }
            }
            result = this.check_empty(result)
            return result
        },
        prise_en_charge_array(param){
            let result = ''
            if(param && param.indication_jo_info_smr && param.indication_jo_info_smr[0] && param.indication_jo_info_smr[0].transparence_smr && param.indication_jo_info_smr[0].transparence_smr[0] && param.indication_jo_info_smr[0].transparence_smr[0].transparence){ 
                 
                let item = param.indication_jo_info_smr[0].transparence_smr[0].transparence                
                result += "<div>"+ item['trs_type_demandes'] + " <a href='/detail/" +item['trs_id']+"/trs' target='_blank'>"+this.DDMMMYY(item['trs_date'])+"</a></div><div>" 
                result += param.indication_jo_info_smr[0].transparence_smr[0].smr_type ?  param.indication_jo_info_smr[0].transparence_smr[0].smr_type  : ''
                result += param.indication_jo_info_smr[0].transparence_smr[0].asmr_obtenue ?  ' - ' + param.indication_jo_info_smr[0].transparence_smr[0].asmr_obtenue : ''
                result += "</div>";

                if(item['trs_particularite'] == "Complement de gamme"){
                    result += "<div>"+ item['trs_particularite'] +"</div>"          
                }
            }
            result = this.check_empty(result)
            return result
        },
        max_row(param){
            const temp = []
            temp.push(1)              
            if(param.indication_jo_info){
                temp.push(param.indication_jo_info.length)
            }
            return Math.max(...temp)
        },
        eventHandlerResize,
        modifyPage,
        DDMMMYY,
        check_empty,
        odd_even_border
    },
    computed: {
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        data(){
            return this.$store.getters['advanced_result/data'];
        },
        orderColumn(){
            return this.$store.getters['advanced_result/column']; 
        },
        default_column(){
            return this.$store.getters['advanced_result/default_column'];
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        }, 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load']){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        }
    },
    created() {
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("essais_clinique/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
}
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>